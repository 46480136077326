import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Tooltip } from '@mui/material'
import Add from '@mui/icons-material/Add'

/**
 * A custom create button to replace React Admin's because we don't want
 * their free responsive solutions
 * @param resource
 * @param label
 * @param className
 * @param queryString - query string to pass to create component
 * @returns {JSX.Element}
 */
export default ({
    resource,
    label,
    queryString,
    helpText = null,
    variant = 'contained',
}) => {
    return helpText ? (
        <Tooltip
            title={helpText}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        fontSize: '14px',
                        padding: '8px ',
                        backgroundColor: 'rgba(0,0,0,0.87)',
                    },
                },
            }}
            placement="bottom"
            disableFocusListener
            disableTouchListener
        >
            <Button
                component={Link}
                to={
                    queryString
                        ? `/${resource}/create${queryString}`
                        : `/${resource}/create`
                }
                variant={variant}
            >
                <Add sx={{ marginLeft: '-4px', marginRight: '6px' }} />{' '}
                {label || 'Create'}
            </Button>
        </Tooltip>
    ) : (
        <Button
            component={Link}
            to={
                queryString
                    ? `/${resource}/create${queryString}`
                    : `/${resource}/create`
            }
            variant={variant}
        >
            <Add sx={{ marginLeft: '-4px', marginRight: '6px' }} />{' '}
            {label || 'Create'}
        </Button>
    )
}
