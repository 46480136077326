import React from 'react'
import { Datagrid } from 'react-admin'

export default ({ children, rowClick = null, empty = null, ...props }) => {
    return (
        <Datagrid
            rowClick={rowClick}
            {...props}
            sx={{
                '& .RaDatagrid-table': {
                    border: '1px solid #DBDEE5',
                },
            }}
            empty={empty}
            bulkActionButtons={props.bulkActionButtons}
        >
            {React.Children.map(children, (child) => {
                if (child === null) return null
                return React.cloneElement(child)
            })}
        </Datagrid>
    )
}
